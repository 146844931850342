import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: "ENG",
    activationTypes: [],
    stakingTypes: [],
    stakingDaysType: [],
    activationForm: {
      email: null,
      password: null,
      exchangeType: null,
      amount: null,
      wallet: null,
      ref: null,
      terms: false,
      step1: false,
      walletToTransfer: null
    },
    exchangeForm: {
      email: null,
      password: null,
      exchangeType: null,
      wallet: null,
      terms: false
    }
  },
  mutations: {
    changeLanguage(state, payload) {
      state.lang = payload;
    },
    setActivationTypes(state, payload) {
      state.activationTypes = payload;
    },
    setStakingTypes(state, payload) {
      state.stakingTypes = payload;
    }
  },
  actions: {
    getActivationTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/activation/account_types")
          .then(response => {
            commit('setActivationTypes', response.data.items)
            resolve(response.data)
          })
          .catch(({response}) => {
            console.error(response);
            reject(response);
          });
      })
    },
    getStakingTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/staking/types")
          .then(response => {
            commit('setStakingTypes', response.data.items)
            resolve(response.data)
          })
          .catch(({response}) => {
            console.error(response);
            reject(response);
          });
      })
    },
    // eslint-disable-next-line no-unused-vars
    sendActivation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/api/activation/request_by_password",
            payload
          )
          .then(response => {
            resolve(response.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendActivationBusd({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/api/activation/request_by_password",
            payload
          )
          .then(response => {
            resolve(response.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendStaking({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/api/staking/request_by_password",
            payload
          )
          .then(response => {
            resolve(response.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    sendStakingBusd({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/api/staking/busd/request_by_password",
            payload
          )
          .then(response => {
            resolve(response.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    getExchangePrice({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = {
          amount: payload.amount
        };
        // console.log("data:", data, payload);
        axios.post('/api/qc/account/depo/crypto/exchange-price-v1', data)
          .then(response => {
            resolve(response.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    exchangeUsdtToFaUnit({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = {
          username: payload.email,
          password: payload.password,
          wallet: payload.wallet,
          amount: payload.amount
        };
        axios.post('/api/qc/account/depo/crypto/refill-by-password-v1', data)
          .then(response => {
            resolve(response.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    exchangeFaUnittoUsdt({ commit },payload) {
      return new Promise((resolve, reject) => {
        const data = {
          username: payload.email,
          password: payload.password,
          wallet: payload.wallet,
          amount: payload.amount
        };
        axios.post('/api/qc/account/depo/crypto/withdrawal-by-password-v1', data)
          .then(response => {
            resolve(response.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    getBusdWallet({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/blockchain_info/busd/wallet")
          .then(response => {
            resolve(response.data)
          })
          .catch(({response}) => {
            console.error(response);
            reject(response);
          });
      })
    },
    // eslint-disable-next-line no-unused-vars
    exchangeBusdIn({ commit },payload) {
      return new Promise((resolve, reject) => {
        const data = {
          email: payload.email,
          tx_id: payload.tx_id,
          tx_type: payload.tx_type
        };
        axios.post('/api/blockchain_info/busd/register_transaction', data)
          .then(response => {
            resolve(response.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    exchangeBusdOut({ commit },payload) {
      return new Promise((resolve, reject) => {
        const data = {
          username: payload.username,
          password: payload.password,
          busd_wallet: payload.wallet,
          amount: Number(payload.amount)
        };
        axios.post('/api/blockchain_info/busd/withdraw', data)
          .then(response => {
            resolve(response.data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
  },
  modules: {}
});
