<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
export default {
  computed: {
    layout() {
      return this.$route.meta.layout || "main-layout";
    }
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");


</style>
