import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
// import ElementPlus from "element-plus";
//import "element-plus/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/index.css";
import "./main.css";
import locale from "element-ui/lib/locale/lang/en";
import i18n from './i18n';
// import i18n from "@/utils/i18n";
import axios from 'axios';
import VueAxios from "vue-axios";
// import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";
import MainLayout from "@/layouts/MainLayout";

Vue.component("main-layout", MainLayout);
Vue.config.productionTip = false;
Vue.use(ElementUI, {locale})
Vue.use(VueAxios, axios);
// Vue.component('font-awesome-icon', FontAwesomeIcon);
// Vue.component('font-awesome-layers', FontAwesomeLayers);
// Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
const url = process.env.VUE_APP_URL; //"http://192.168.1.104:8005";
Vue.axios.defaults.baseURL = url;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");

// createApp(App)
//   .use(store)
//   .use(router)
//   .use(ElementPlus)
//   .use(ElementPlus, { locale })
//   .use(i18n)
//   .use(VueAxios, axios)
//   .mount("#app");
