<template>
  <div id="main">
    <div id="nav">
      <a href="/">
        <img src="../assets/logo.png" width="140" height="59" />
      </a>
      <div class="user-language">
        <img src="../assets/user-icon.png" width="32" height="32" style="margin-right: 20px"/>
        <LanguageComponent  />
      </div>
    </div>

    <img src="../assets/circle.png" width="103" height="161" class="circle" />
    <img src="../assets/dotted.png" width="70" height="162" class="dotted" />

    <router-view />
    <FooterComponent />
  </div>
</template>

<script>
import LanguageComponent from "@/components/LanguageComponent";
import FooterComponent from "@/components/FooterComponent";

export default {
  name: "MainLayout",
  components: {
    LanguageComponent,
    FooterComponent
  }
};
</script>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
  background-image: url("../assets/background.png")!important;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#main {
  max-width: 1220px;
  margin: auto;
}

.circle {
  position: absolute;
  right: 0;
  top: 481px;
}
.dotted {
  position: absolute;
  left: 0;
  top: 1042px;
}

#nav {
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
// background: #2c3e50;
}
.user-language {
  display: flex;
  align-items: center;
}
</style>
