<template>
  <el-dropdown class="drop-menu" @command="handleCommand">
    <span class="drop-menu">{{lang}} <img src="../assets/down-arrow.png" width="24" height="15" style="margin-left: 12px" ></span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="RUS">RUS</el-dropdown-item>
        <el-dropdown-item command="ENG">ENG</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "LanguageComponent",
  data() {
    return {
      menu: [
        {
          value: "RUS",
          label: "RUS"
        },
        {
          value: "ENG",
          label: "ENG"
        }
      ],
      value: "RUS"
    };
  },
  computed: {
    ...mapState(["lang"])
  },
  methods: {
    ...mapMutations(["changeLanguage"]),
    handleCommand(command) {
      this.changeLanguage(command)
      if (command === 'RUS') {
        this.$i18n.locale = "ru"; // .setLocale("ru")
       } else if (command === "ENG") {
        this.$i18n.locale = "en"; //.setLocale("en")
       }
    }
  }
};
</script>

<style scoped>
.drop-menu {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
</style>
