<template>
  <el-row :class="[
    'item-menu',
    disabled ? 'disabled-background' : ''
    ]">
    <el-col :span="2" :xs="10">
      <img
        class="image-icon"
        :src="require('@/assets/' + icon)"
        width="60"
        height="60"
      />
    </el-col>
    <el-col :span="22" >
      <el-row type="flex" justify="start" class="title-text">
        {{ title }}
      </el-row>
      <el-row type="flex" justify="start" class="description-text">
        {{ description }}
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "BlockMenuItem",
  props: ["icon", "title", "description", "disabled"]
};
</script>

<style scoped>
.item-menu {
  background: #ffffff;
  /* Shadow */

  box-shadow: 15px 15px 40px rgba(55, 84, 170, 0.07);
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
}
.disabled-background {
  opacity: 0.6;
}
.image-icon {
  margin-right: 20px;
}
.title-text {
  color: #d8ad4c;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px;

}

.description-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: start;
  /* identical to box height */

  /* 465A64 */

  color: #252542;
}

@media screen and (max-width: 600px) {
  .title-text, .description-text {
    font-size: 14px;
    text-align: start;
  }
}
</style>
