<template>
  <div class="home">
    <div class="main-title">{{ $t('fridnApplication') }}</div>
    <el-row type="flex" justify="start">
      <a href="#fix">
        <img
          src="../assets/chevrons-down.png"
          width="78"
          height="78"
          class="chevron"
        />
      </a>
    </el-row>
    <div id="fix" class="text-title">{{ $t('mainTitle') }}</div>
    <router-link
      v-for="item in menu"
      :key="item.title"
      :to="item.url"
    >
      <BlockMenuItem
        :icon="item.icon"
        :title="$t(item.title)"
        :description="$t(item.description)"
        :disabled="item.disabled"
      />
    </router-link>

  </div>
</template>

<script>
import BlockMenuItem from "@/components/BlockMenuItem";
export default {
  name: "Home",
  components: {
    BlockMenuItem
  },
  data() {
    return {
      menu: [
        {
          icon: "menu-exchange-icon.svg",
          title: "mainMenuExchange",
          description: "mainMenuExchangeDescription",
          url: "/usdt_exchange",
          disabled: false
        },
        {
          icon: "menu-exchange-icon.svg",
          title: "mainMenuExchangeUSDT",
          description: "mainMenuExchangeUSDTDescription",
          url: "/busd_exchange",
          disabled: false
        },
        {
          icon: "menu-user-icon.svg",
          title: "mainMenuActivation",
          description: "mainMenuActivationDescription",
          url: "/busd_activation",
          disabled: false
        },
        {
          icon: "menu-coin-icon.svg",
          title: "mainMenuStaking",
          description: "mainMenuStakingDescription",
          url: "/usdt_staking",
          disabled: false
        },
        {
          icon: "menu-f-icon.svg",
          title: "mainMenuDefi",
          description: "mainMenuDefiDescription",
          url: "/",
          disabled: true
        }
      ]
    };
  }
};
</script>
<style scoped>

/*@media (min-width: 900px) and (max)  {*/
/*  .home {*/
/*    margin-left: 30px;*/
/*    margin-right: 30px;*/
/*  }*/
/*}*/

.home {
  margin-bottom: 234px;
  margin-left: 30px;
  margin-right: 30px;
}

.chevron {
  margin-bottom: 180px;
}
.text-title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 90px;
  text-align: start;
}
</style>
