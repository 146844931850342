<template>
  <div>
    <div class="footer-nav">
      <div>
        <a href="https://fridn.com">
          <div class="media-logo"><img src="../assets/logo.png" width="140" height="59"></div>
        </a>
        <div class="media-icon">
          <a href="https://www.youtube.com/channel/UCg5SsV_Z5_12dox_Gft64ZQ"><font-awesome-icon :icon="youtube" size="lg" style="color: #252542" /></a>
          <a href="https://twitter.com/Fridn_official"><font-awesome-icon :icon="twitter" size="lg" style="color: #252542" /></a>
          <a href="https://www.facebook.com/fridncom/"><font-awesome-icon :icon="facebook" size="lg" style="color: #252542" /></a>
          <a href="https://www.instagram.com/fridn_official/?igshid=47k0ja763ax2"><font-awesome-icon :icon="instagram" size="lg" style="color: #252542" /></a>
          <a :href="$t('telegramLink')"><font-awesome-icon :icon="telegram" size="lg" style="color: #252542" /></a>
          <a href="https://www.linkedin.com/company/fridn-official/"><font-awesome-icon :icon="linkedin" size="lg" style="color: #252542" /></a>
          <a href="https://medium.com/@fridn_ark"><font-awesome-icon :icon="medium" size="lg" style="color: #252542" /></a>
          <a href="https://zen.yandex.ru/id/5f68b13e8eac70585b2d1f48"><img src="../assets/zen-icon.svg" width="18.67" height="18.67"></a>
        </div>
      </div>
      <div class="footer-nav-links">
<!--        <div class="footer-nav-links__list">-->
<!--          <router-link to="/">About us</router-link>-->
<!--          <router-link to="/">Private</router-link>-->
<!--          <router-link to="/">Business</router-link>-->
<!--          <router-link to="/">FAR</router-link>-->
<!--          <router-link to="/">Marketplace</router-link>-->
<!--        </div>-->
<!--        <div class="footer-nav-links__list">-->
<!--          <router-link to="/">FAR Rewards & eFAR</router-link>-->
<!--          <router-link to="/">Fridn Partners</router-link>-->
<!--          <router-link to="/">Team & Community</router-link>-->
<!--          <router-link to="/">Technology & Security</router-link>-->
<!--          <router-link to="/">Contacts & FAQ</router-link>-->
<!--        </div>-->
      </div>
    </div>
    <div class="copyright">
      <div>Copyright 2017-2020 © N.KRAT Group AG, All rights reserved. For company information and other legal bits, see our Imprint</div>
      <div>Privacy Policy</div>
      <div>Terms & Conditions</div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faYoutube, faTwitter, faFacebook, faInstagram, faTelegram, faLinkedinIn, faMedium } from '@fortawesome/free-brands-svg-icons'
export default {
  name: "FooterComponent",
  data() {
    return {
      youtube: faYoutube,
      twitter: faTwitter,
      facebook: faFacebook,
      instagram: faInstagram,
      telegram: faTelegram,
      linkedin: faLinkedinIn,
      medium: faMedium
    }
  },
  components: {
    FontAwesomeIcon
  }
};
</script>

<style lang="scss">

.media-logo {
  text-align: start;
}

.media-icon {
  margin-top: 54px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 300px;
}
.footer-nav {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-left: 30px;
  margin-right: 30px;
}
.footer-nav-links {
  display: flex;
  justify-content: right;
}
.footer-nav-links__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 154px;
  a {
    font-weight: 400;
    color: #2c3e50;
  }
  &:last-of-type {
    margin-left: 50px;
  }
}
.copyright {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 99px 30px 144px;
}
</style>
